<template>
  <div class="terms">
    <h3 class="terms__title">
      {{ termsTitle }}
    </h3>
    <template v-if="termStore.currentTerm">
      <div class="terms__subtitle">
        {{ termStore.currentTerm.title }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <wysiwyg-viewer v-html="termStore.currentTerm.text" />
      <div class="terms__footer">
        <Button
          size="regular"
          type="secondary"
          :text="buttonText"
          @click="onCloseTerms"
          >{{ $t('global.close') }}
        </Button>
      </div>
    </template>
    <Loader v-else />
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import { i18n } from '@src/localization/config';
import Loader from '@components/BaseComponents/Loader.vue';
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'Terms',
  components: {
    Button,
    Loader,
    WysiwygViewer,
  },
  setup() {
    const termStore = useTermStore();
    termStore.fetchTerms({ code: 'resf_agreement' });
    return { termStore };
  },
  computed: {
    termsTitle() {
      return i18n.t('terms.agreements_user_single');
    },
    buttonText() {
      return i18n.t('global.close');
    },
  },
  methods: {
    onCloseTerms() {
      this.$emit('closeTerms');
    },
  },
};
</script>

<style lang="scss" scoped>
.terms__title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
}
.terms__subtitle {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid rgba(white, 0.1);
  padding-bottom: 16px;
  margin-bottom: 20px;
}
.terms__footer {
  text-align: center;
  margin-top: 32px;
}
</style>
