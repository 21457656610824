<template>
  <div v-if="isVisibleComponent" class="pd-personal-solo">
    <h3 class="pd-personal-solo__title">{{ pdSoloMainTitle }}</h3>

    <Notification
      :title="pdSoloTitle"
      type="warning"
      show-icon
      horizontal
    />

    <Button
      class="pd-personal-solo__button"
      type="primary"
      size="regular"
      :text="fillPdText"
      @click="openPdPopup"
    />
    <InfoBox v-if="error" variant="error">{{ error }}</InfoBox>
  </div>
</template>

<script>
import api from '@utils/api';
import { i18n } from '@src/localization/config';
import Button from '@components/v2/ui/Button.vue';
import Notification from '@components/v2/ui/Notification.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'PersonalDataSoloRequired',
  components: {
    Button,
    Notification,
    InfoBox,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters('tournamentRegistration', ['isNeedFksPDFields']),
    ...mapGetters('tournaments', ['getTournament']),

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    numTeamMembers() {
      return this.tournament?.numTeamMembers;
    },

    isSoloTournament() {
      return this.numTeamMembers === 1;
    },

    isVisibleComponent() {
      return this.isNeedFksPDFields && this.isSoloTournament;
    },

    pdSoloMainTitle() {
      return i18n.t('registration.title');
    },
    pdSoloTitle() {
      return i18n.t('tournaments.pd-solo-required-info');
    },
    fillPdText() {
      return i18n.t('global.fill');
    },
  },
  methods: {
    async openPdPopup() {
      try {
        const newWindow = window.open();
        const { url } = await api.get('/profile/resf/pd-link');
        newWindow.location = url;
      } catch (err) {
        this.error = err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pd-personal-solo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.pd-fill-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.pd-personal-solo__title {
  font-size: 24px;
  text-align: center;
}
</style>
