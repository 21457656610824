<template>
  <div class="contacts-form">
    <h3 class="title">{{ mainTitle }}</h3>
    <Notification
      class="notification"
      :title="notifyTitle"
      type="warning"
      show-icon
      horizontal
    />
    <div v-if="$slots['main-title']" class="contacts-title">
      <slot name="main-title" />
    </div>

    <InfoBox
      v-if="!canEditProfile && !forceAllowEdit"
      variant="warning"
    >
      <i18n path="profile.edit_warnings_tournament_text">
        <BaseLink slot="link" :to="{ name: 'my-profile' }">
          {{ $t('profile.edit_warnings_tournament_link') }}
        </BaseLink>
      </i18n>
    </InfoBox>
    <InfoBox v-else>
      {{ $t('profile.edit_warnings_important') }}
    </InfoBox>

    <email-form v-if="!forceAllowEdit" class="form-input" />

    <BaseForm class="contacts-main" @submit="save">
      <BaseInput
        v-if="fieldIsVisible('discord')"
        v-model="discord"
        class="form-input"
        label="Discord"
        placeholder="example#1337"
        :error-message.sync="errors.discord"
      />

      <div v-if="$slots['additional-title']" class="contacts-title">
        <slot name="additional-title" />
      </div>

      <BaseInput
        v-if="fieldIsVisible('mobile')"
        v-model="mobilePhone"
        class="form-input"
        :label="$t('players.phone_label')"
        :error-message="errors.mobile"
        placeholder="+7 (912) 345-67-89"
        @change="formatMobile"
      />

      <BaseInput
        v-if="fieldIsVisible('website')"
        v-model="website"
        placeholder="https://example.com"
        :label="$t('global.site')"
        :error-message.sync="errors.website"
      />

      <BaseInput
        v-if="fieldIsVisible('extraNotes')"
        v-model="extraNotes"
        class="form-input"
        rows="4"
        :placeholder="$t('inputs.interesting')"
        :is-area="true"
        :label="$t('info.additional_title')"
        :error-message.sync="errors.extraNotes"
      />

      <InfoBox v-if="success" variant="success">
        {{ $t('save.success') }}
      </InfoBox>

      <InfoBox v-if="hasErrors" variant="error">
        {{ $t('save.error') }}
      </InfoBox>

      <pvp-btn
        :disabled="saveButtonDisabled"
        :is-loading="loadingState.update"
        type="submit"
        class="submit-btn"
      >
        {{ $t('save.single') }}
      </pvp-btn>
    </BaseForm>
  </div>
</template>

<script>
import { formatPhone } from '@utils/phones';
import EmailForm from '@components/ProfileComponents/ProfileEdit/EmailForm.vue';
import { i18n } from '@src/localization/config';
import Notification from '@components/v2/ui/Notification.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'CheckUserContacts',
  components: {
    EmailForm,
    Notification,
    BaseForm,
    BaseLink,
    BaseInput,
    InfoBox,
  },
  props: {
    forceAllowEdit: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    isShowAllFields: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // State
      loadingState: {
        ...this.loadingState,
        update: false,
      },

      // Data
      discord: null,
      mobilePhone: null,
      website: null,
      extraNotes: null,

      // Errors
      errors: {},
      success: false,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'getCurrentProfileSettings',
      'getCurrentProfile',
    ]),

    mainTitle() {
      return i18n.t('registration.title');
    },
    notifyTitle() {
      return i18n.t('registration.contacts');
    },
    hasErrors() {
      return Object.values(this.errors).length > 0;
    },

    canEditProfile() {
      return this.getCurrentProfileSettings.canEditProfile;
    },

    saveButtonDisabled() {
      return (
        !this.forceAllowEdit &&
        (this.loadingState.update || !this.canEditProfile)
      );
    },
  },
  created() {
    const profile = this.getCurrentProfile;

    this.discord = profile.messengers.discord;

    this.mobilePhone = profile.mobile;
    this.formatMobile();

    this.website = profile.website;
    this.extraNotes = profile.extraNotes;
  },
  methods: {
    ...mapActions('profile', ['fetchProfileUpdate']),

    fieldIsVisible(field) {
      return this.isShowAllFields || this.fields?.includes(field);
    },

    formatMobile() {
      this.mobilePhone = formatPhone(this.mobilePhone);
    },

    save() {
      this.success = false;
      this.errors = {};
      this.loadingState.update = true;

      if (this.fields.length) {
        const mapped = {
          mobile: this.mobilePhone,
          discord: this.discord,
          website: this.website,
          extraNotes: this.extraNotes,
        };

        const errors = this.fields.filter((field) => !mapped[field]);

        if (errors.length) {
          this.errors = errors.reduce(
            (acc, field) => ({
              ...acc,
              [field]: this.$t('profile.required'),
            }),
            {},
          );

          this.loadingState.update = false;

          return;
        }
      }

      this.fetchProfileUpdate({
        mobile: this.mobilePhone
          ? this.mobilePhone.replace(/[\D]/g, '')
          : null,
        discord: this.discord,
        website: this.website,
        extraNotes: this.extraNotes,
      })
        .then(() => {
          this.success = true;

          this.$emit('done', 'isTeamValidated');
        })
        .catch(({ error = [] }) => {
          this.errors = _.reduce(
            error,
            (acc, err, field) => ({
              ...acc,
              [field]: err.replace(/[&]quot[;]/giu, '"'),
            }),
            {},
          );
        })
        .finally(() => {
          this.loadingState.update = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-main {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(white, 0.1);
}

.contacts-title {
  margin-bottom: 16px;
}

.pvp-form {
  .contacts-title {
    margin-top: 40px;
  }
}

.email-form,
.pvp-info-box {
  margin-top: 20px;
}

.contacts-form {
  font-size: 14px;
}

.submit-btn {
  margin-top: 30px;
  @include max-tablet() {
    width: 100%;
  }
}

.form-input {
  display: block;
  margin-bottom: 8px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
}
</style>
