<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="registration-without-linked-game">
    <h3 class="registration-without-linked-game__title">
      {{ title }}
    </h3>
    <div class="registration-without-linked-game__infobox">
      <InfoBox v-if="infobox.length" variant="warning">
        <p v-for="(msg, key) in infobox" :key="key" v-html="msg"></p>
      </InfoBox>
    </div>
    <div class="registration-without-linked-game__linker">
      <GameAccountLinker
        :id="gameId"
        @mounted="handleLinkerMounted"
        @linked="$emit('isGameLinked')"
        @closed="$emit('isGameLinked')"
        @closeModal="$emit('isGameLinked')"
      />
    </div>
  </div>
</template>

<script>
import GameAccountLinker from '@components/ProfileComponents/GameAccount/GameAccountLinker.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

import { i18n } from '@src/localization/config';

export default {
  name: 'RegistrationWithoutLinkedGame',
  components: {
    InfoBox,
    GameAccountLinker,
  },
  props: {
    gameId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      infobox: [],
      game: '',
    };
  },
  computed: {
    title() {
      return i18n.t('profile.binding_games_need_account');
    },
  },
  methods: {
    handleLinkerMounted(data) {
      this.game = data.game;
      this.showGameAccountLinkWarnings();
    },
    showGameAccountLinkWarnings() {
      switch (this.game?.linkType) {
        case 'steam': {
          const link = {
            text: i18n.t('registration.binding_steam_link'),
            href: this.game.instructions.find(
              (inst) => inst.code === 'steam_public',
            )?.url,
          };
          this.infobox.push(
            i18n.t('registration.errors_game_account', {
              acc: '<b>Steam</b>',
              game: `<b>${this.game.name}</b>`,
            }),
            i18n.t('registration.binding_management'),
            i18n.t('registration.binding_steam_text', {
              acc: '<b>Steam</b>',
              pvp: '<b>VKPlay</b>',
              link: `<a target="_blank" href="${link.href}">${link.text}</a>`,
            }),
          );
          break;
        }
        case 'battlenet':
          this.infobox.push(
            i18n.t('registration.errors_game_account', {
              acc: '<b>Battle.net</b>',
              game: `<b>${this.game.name}</b>`,
            }),
          );
          break;
        case 'link':
          this.infobox.push(
            i18n.t('registration.errors_game_auto', {
              game: `<b>${this.game.name}</b>`,
            }),
          );
          break;
        default:
          this.infobox.push(
            i18n.t('registration.errors_game_name', {
              game: `<b>${this.game.name}</b>`,
            }),
          );
      }
    },
  },
};
</script>

<style scoped>
.registration-without-linked-game__infobox {
  margin-bottom: 12px;
}
.registration-without-linked-game__title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}
</style>
