<template>
  <div>
    <h3 class="title">{{ mainTitle }}</h3>
    <Notification
      class="notification"
      :title="notifyTitle"
      type="warning"
      show-icon
      horizontal
    />
    <div class="personal-form"></div>

    <InfoBox
      v-if="!canEditProfile && !forceAllowEdit"
      variant="warning"
    >
      <i18n path="profile.edit_warnings_tournament_text">
        <BaseLink slot="link" :to="{ name: 'my-profile' }">
          {{ $t('profile.edit_warnings_tournament_link') }}
        </BaseLink>
      </i18n>
    </InfoBox>

    <BaseForm @submit="save">
      <div v-if="fieldIsVisible('gender')" class="radio-group">
        <div class="label">{{ $t('players.gender_label') }}</div>
        <BaseRadio v-model="gender" value="m">
          {{ $t('players.gender_male') }}
        </BaseRadio>
        <BaseRadio v-model="gender" value="f">
          {{ $t('players.gender_female') }}
        </BaseRadio>
      </div>

      <BaseInput
        v-if="fieldIsVisible('lastName')"
        v-model="lastName"
        class="form-input"
        :error-message.sync="errors.lastName"
        :label="$t('players.lastName_label')"
        :placeholder="$t('players.lastName_placeholder')"
      />

      <BaseInput
        v-if="fieldIsVisible('firstName')"
        v-model="firstName"
        class="form-input"
        :label="$t('players.name_label')"
        :error-message.sync="errors.firstName"
        :placeholder="$t('players.name_placeholder')"
      />

      <BaseInput
        v-if="fieldIsVisible('middleName')"
        v-model="middleName"
        class="form-input"
        :label="$t('players.middleName_label')"
        :error-message.sync="errors.middleName"
        :placeholder="$t('players.middleName_placeholder')"
      />

      <pvp-datepicker
        v-if="fieldIsVisible('birthdate')"
        v-model="birthDate"
        class="form-input"
        :label="$t('players.birthdate_label')"
        :error-message.sync="errors.birthdate"
        :min="datePickerLimits.min"
        :max="datePickerLimits.max"
        :placeholder="$t('players.birthdate_placeholder')"
        @input="errors.birthdate = ''"
      />

      <CountrySelect
        v-if="fieldIsVisible('idCountry')"
        v-model="countryId"
        class="form-input"
        :list-height="100"
        :error-message.sync="errors.idCountry"
        :label="$t('players.region_label')"
      />

      <region-select
        v-if="fieldIsVisible('idRegion') && regionsVisible"
        v-model="regionId"
        class="form-input"
        :list-height="100"
        :error-message.sync="errors.idRegion"
        :label="$t('players.region_alternative_label')"
      />

      <BaseInput
        v-if="fieldIsVisible('city')"
        v-model="city"
        class="form-input"
        :label="$t('players.city_label')"
        :error-message.sync="errors.city"
        :placeholder="$t('players.city_placeholder')"
      />

      <InfoBox v-if="success" variant="success">
        {{ $t('save.success') }}
      </InfoBox>

      <InfoBox v-if="hasErrors" variant="error">
        {{ $t('save.error') }}
      </InfoBox>

      <pvp-btn
        class="submit-btn"
        :disabled="saveButtonDisabled"
        :is-loading="isLoading"
        type="submit"
      >
        {{ $t('save.single') }}
      </pvp-btn>
    </BaseForm>
  </div>
</template>

<script>
import { leadingZero } from '@utils/dates';
import { i18n } from '@src/localization/config';
import Notification from '@components/v2/ui/Notification.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import CountrySelect from '@components/BaseComponents/Select/CountrySelect.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import RegionSelect from '@components/BaseComponents/Select/RegionSelect.vue';
import BaseRadio from '@components/BaseComponents/Form/Radio.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'CheckUserProfile',
  components: {
    Notification,
    BaseForm,
    CountrySelect,
    BaseInput,
    RegionSelect,
    BaseRadio,
    BaseLink,
    InfoBox,
  },
  props: {
    forceAllowEdit: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,

      // Data
      gender: null,

      firstName: null,
      lastName: null,
      middleName: null,

      countryId: null,
      regionId: null,
      city: null,

      birthDate: null,

      // Errors
      errors: {},
      success: false,
    };
  },
  computed: {
    ...mapState('application', ['countryRussiaId']),
    ...mapGetters('profile', [
      'getCurrentProfileSettings',
      'getCurrentProfile',
    ]),

    mainTitle() {
      return i18n.t('registration.title');
    },

    notifyTitle() {
      return i18n.t('registration.errors_info');
    },

    datePickerLimits() {
      return {
        min: new Date(1940, 0, 1),
        max: new Date(new Date().getFullYear() - 10, 0, 0),
      };
    },

    hasErrors() {
      return Object.values(this.errors).length > 0;
    },

    canEditProfile() {
      return this.getCurrentProfileSettings.canEditProfile;
    },

    saveButtonDisabled() {
      return (
        !this.forceAllowEdit &&
        (this.isLoading || !this.canEditProfile)
      );
    },

    regionsVisible() {
      return this.countryId === this.countryRussiaId;
    },
  },
  created() {
    const profile = this.getCurrentProfile;

    this.gender = profile.gender;

    this.countryId = profile.idCountry;
    this.regionId = profile.idRegion;
    this.city = profile.city;

    this.firstName = profile.firstName;
    this.lastName = profile.lastName;
    this.middleName = profile.middleName;

    this.birthDate = this.birthdateToTs(profile?.birthdate);
  },
  methods: {
    ...mapActions('profile', ['fetchProfileUpdate']),
    fieldIsVisible(field) {
      if (this.fields.length) {
        return this.fields.includes(field);
      }
      return true;
    },

    birthdateToTs(str) {
      if (str) {
        const [year, month, date] = str.split('-');
        return Math.trunc(
          new Date(year, month - 1, date).getTime() / 1000,
        );
      }
      return '';
    },

    tsToBirthdate(ts) {
      if (Boolean(ts) === false) {
        return '';
      }

      const dt = new Date(ts * 1000);
      const year = dt.getFullYear();
      const month = leadingZero(dt.getMonth() + 1);
      const date = leadingZero(dt.getDate());
      return `${year}-${month}-${date}`;
    },

    validateErrors() {
      this.errors = {};
      if (this.fields.length) {
        const mapped = {
          gender: this.gender,
          firstName: this.firstName,
          lastName: this.lastName,
          middleName: this.middleName,
          idCountry: this.countryId || null,
          idRegion: this.regionId || null,
          city: this.city,
          birthdate: this.birthDate,
        };

        if (this.countryId !== this.countryRussiaId) {
          delete mapped.idRegion;
        }

        const errors =
          this.countryId === this.countryRussiaId
            ? this.fields.filter((field) => !mapped[field])
            : this.fields
                .filter((field) => field !== 'idRegion')
                .filter((field) => !mapped[field]);

        if (errors.length) {
          this.errors = errors.reduce(
            (acc, field) => ({
              ...acc,
              [field]: this.$t('profile.required'),
            }),
            {},
          );

          return true;
        }
      }

      if (this.birthDate === undefined) {
        this.$set(
          this.errors,
          'birthdate',
          this.$t('players.birthdate_warning'),
        );
        return true;
      }

      return false;
    },

    save() {
      this.success = false;

      if (this.validateErrors()) {
        return false;
      }

      this.isLoading = true;

      return this.fetchProfileUpdate({
        birthdate: this.tsToBirthdate(this.birthDate),
        gender: this.gender,
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.middleName,
        idCountry: this.countryId || null,
        idRegion:
          this.countryId === this.countryRussiaId
            ? this.regionId
            : null,
        city: this.city,
      })
        .then(() => {
          this.success = true;
          this.$emit('done', 'isTeamValidated');
        })
        .catch(({ error = [] }) => {
          this.errors = _.reduce(
            error,
            (acc, err, field) => ({
              ...acc,
              [field]: err.replace(/[&]quot[;]/giu, '"'),
            }),
            {},
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';
.personal-form {
  font-size: 14px;
}

.radio-group {
  margin-bottom: 1em;

  .label {
    font-size: em(13px);
    color: rgba(white, 0.5);
  }

  .radio {
    display: inline-block;

    & + .radio {
      margin-left: 12px;
    }
  }
}

.pvp-form,
.pvp-info-box {
  margin-top: 20px;
}

.submit-btn {
  margin-top: 30px;
  width: 100%;
  @include min-tablet() {
    width: auto;
  }
}

.form-input {
  display: block;
  margin-bottom: 8px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
}
</style>
