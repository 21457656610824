<template>
  <div>
    <component
      :is="current.component"
      v-if="isInited"
      :team-hash="currentTeamHash"
      :tournament-id="tournamentId"
      :user-profile-fields="requiredFields?.profile"
      :user-profile-contacts="requiredFields?.contacts"
      :heartstone-decks="heartstoneDecks"
      @selectTeam="setCurrentTeam"
      @closeModal="closeModal"
      @isGameLinked="isGameLinked"
      @heartstone:success="handleHeartstoneDeckSuccess"
      @teamValidated:success="handleTeamValidated"
      @finishRegistration="handleFinishRegistration"
    />
  </div>
</template>

<script>
import GameLink from './ui/GameLink';
import TeamLink from './ui/TeamLink';
import TeamValidate from './ui/TeamValidate';
import FormPD from './ui/FormPD';
import HeartstoneDecks from './ui/HeartstoneDecks';
import Result from './ui/Result';

export default {
  name: 'TournamentRegistration',

  components: {
    GameLink,
    TeamLink,
    TeamValidate,
    FormPD,
    HeartstoneDecks,
    Result,
  },

  data() {
    return {
      predicates: {
        isNotLinkedGame: false,
        isNotCheckedTeam: false,
        isNotWritedPD: false,
        isNotValidatedTeam: false,
        isNeedSetHSDecks: false,
      },
      isInited: false,
      currentTeam: null,
      heartstoneDecks: [],
    };
  },

  computed: {
    ...mapState('tournamentRegistration', [
      'settings',
      'hearthstonePick',
      'needFksPDFields',
    ]),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('profile', [
      'getLinkedGameAccount',
      'getCurrentProfile',
      'getCurrentProfileDetails',
    ]),
    ...mapState('application', ['countryRussiaId']),

    tournamentId() {
      return this.$route.params.id;
    },
    tournament() {
      return this.getTournament(this.tournamentId);
    },
    numTeamMembers() {
      return this.tournament?.numTeamMembers;
    },
    isSoloTournament() {
      return this.numTeamMembers === 1;
    },
    gameId() {
      return this.tournament?.idGame;
    },

    steps() {
      return [
        {
          state: 'linking',
          isActive: this.predicates.isNotLinkedGame, // ответ бека игра не привязана
          component: 'GameLink',
        },
        {
          state: 'checking',
          isActive: this.predicates.isNotCheckedTeam, // ответ бека на проверку есть ли команда
          component: 'TeamLink',
        },
        {
          state: 'writing',
          isActive: this.predicates.isNotWritedPD, // ответ бека нужно ли заполнять ПД
          component: 'FormPD',
        },
        {
          state: 'validating',
          isActive: this.predicates.isNotValidatedTeam, // нужна ли валидация команды
          component: 'TeamValidate',
        },
        // {
        //   state: 'writing',
        //   isActive: this.predicates.isNotWritedPD, // ответ бека нужно ли заполнять ПД
        //   component: 'FormPD',
        // },
        {
          state: 'settingHS',
          isActive: this.predicates.isNeedSetHSDecks, // ответ бека нужно ли заполнять ПД
          component: 'HeartstoneDecks',
        },
      ];
    },
    current() {
      const defaultStep = {
        state: 'result',
        component: 'Result',
      };

      return this.steps.find((step) => step.isActive) || defaultStep;
    },
    currentTeamHash() {
      return this.currentTeam?.hash;
    },
    teamsList() {
      const profile = this.getCurrentProfile;
      const teams = this.getUserTeams(profile?.hash);
      const { gameId, teamSize } = this.settings || {};
      const spareLimit = this.tournament?.maxReservePlayersCount;
      const checkForSpareCondition = (count) =>
        spareLimit !== null ? count <= spareLimit : true;

      const res =
        teams?.filter(
          ({ idGame, numMembers, role, spareCount }) =>
            idGame === gameId &&
            numMembers === teamSize &&
            role === 'captain' &&
            checkForSpareCondition(spareCount),
        ) || [];
      return res;
    },
    teamNotFull() {
      if (!this.currentTeam?.status) {
        return true;
      }
      return (
        this.currentTeam?.status !== 'formed' ||
        this.currentTeam?.status?.id !== 'formed'
      );
    },
    hasNoTeam() {
      return !this.currentTeam;
    },
    isNeedTeamCheck() {
      if (
        (this.isSoloTournament &&
          !this.hasNoTeam &&
          this.teamsList.length < 2) ||
        (this.isSoloTournament &&
          this.tournament?.maxReservePlayersCount === 0 &&
          this.currentTeam?.spareCount === 1)
      ) {
        return false;
      }

      return (
        this.settings?.teamSize >= 1 &&
        (this.hasNoTeam || this.teamNotFull)
      );
    },
    isHearthstonePick() {
      return (
        this.settings?.hearthstone?.hsLoadDeck &&
        this.hearthstonePick?.length === 0
      );
    },
    isFcsRequired() {
      const { fcs } = this.getCurrentProfileDetails;
      return (
        (this.settings?.fcsRequired && !fcs?.isAccountLinked) ||
        this.needFksPDFields
      );
    },
    requiredFields() {
      const requiredFields = (
        this.settings?.requiredProfileFields || []
      ).filter((field) => {
        const hasMessangers = ['discord'].includes(field);
        return hasMessangers
          ? !this.profile?.messengers?.[field]
          : !this.profile?.[field];
      });

      const profileFields = [
        'gender',
        'firstName',
        'lastName',
        'middleName',
        'idCountry',
        'idRegion',
        'city',
        'birthdate',
      ];
      const contactsFields = [
        'mobile',
        'discord',
        'website',
        'extraNotes',
      ];

      return {
        contacts: requiredFields.filter((field) =>
          contactsFields.includes(field),
        ),
        profile: requiredFields.filter((field) =>
          profileFields.includes(field),
        ),
      };
    },
  },

  watch: {
    teamsList: {
      handler(teams) {
        const firstIndex = 0;
        if (teams?.length === 1) {
          this.currentTeam = teams[firstIndex];
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.isInited = false;
      this.getIsNotLinkedGame();
      this.getIsNotHaveATeam();
      this.getIsNotValidatedTeam();
      this.getIsNeedSetHSDecks();
      this.getIsNotWritedPD();
      this.isInited = true;
    },
    getIsNotLinkedGame() {
      this.predicates.isNotLinkedGame = !this.getLinkedGameAccount(
        this.gameId,
      )?.hasAccount;
    },
    isGameLinked() {
      this.getIsNotLinkedGame();
    },
    getIsNotHaveATeam() {
      this.predicates.isNotCheckedTeam = this.isNeedTeamCheck;
    },
    getIsNotValidatedTeam() {
      this.predicates.isNotValidatedTeam =
        this.requiredFields?.profile.length > 0 ||
        this.requiredFields?.contacts.length > 0;
    },
    getIsNeedSetHSDecks() {
      this.predicates.isNeedSetHSDecks = this.isHearthstonePick;
    },
    getIsNotWritedPD() {
      this.predicates.isNotWritedPD = this.isFcsRequired;
    },
    handleFinishRegistration() {
      this.predicates.isNotCheckedTeam = false;
    },
    handleTeamValidated(payload) {
      if (payload === 'isTeamValidated') {
        this.predicates.isNotValidatedTeam = false;
      }
    },
    handleHeartstoneDeckSuccess(decks) {
      this.heartstoneDecks = decks;
      this.predicates.isNeedSetHSDecks = false;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    setCurrentTeam(team) {
      this.currentTeam = team;
    },
  },
};
</script>
