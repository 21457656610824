<template>
  <main-layout>
    <RegistrationAsUnauthorized v-if="!isLoggedIn" />
    <RegistrationTournamentButton
      v-else
      :tournament-id="tournamentId"
      @startRegistration="openModal"
    />
    <Modal
      v-if="isShowModal"
      type="regular"
      class="modal"
      @close="closeModal"
    >
      <TournamentRegistration @closeModal="closeModal" />
    </Modal>
  </main-layout>
</template>

<script>
import Modal from '@src/components/v2/Modal.vue';
import RegistrationAsUnauthorized from '@src/features/registrationAsUnauthorized';
import RegistrationTournamentButton from '@src/features/registrationTournamentButton';
import TournamentRegistration from '@src/widgets/tournamentRegistration';

export default {
  name: 'TestRegistrationPage',

  components: {
    Modal,
    RegistrationAsUnauthorized,
    RegistrationTournamentButton,
    TournamentRegistration,
  },

  data() {
    return {
      isShowModal: false,
    };
  },

  computed: {
    ...mapGetters('profile', ['isLoggedIn']),

    tournamentId() {
      return this.$route.params.id;
    },
  },

  created() {
    const { secret } = this.$route.query;
    if (secret !== '0YHQtdC60YDQtdGC') {
      this.$router.push({ name: 'not-found' });
    }
  },

  methods: {
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
