<template>
  <Button
    size="regular"
    type="primary"
    :loading="isLoading"
    :text="buttonText"
    @click="startRegistration()"
  />
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import { i18n } from '@src/localization/config';

export default {
  name: 'RegistrationTournamentButton',
  components: { Button },
  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      buttonText: i18n.t('global.register'),
    };
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournamentPage']),
    ...mapActions('tournamentRegistration', [
      'fetchRegistrationData',
    ]),
    ...mapActions('users', ['fetchUserProfile']),
    ...mapActions('profile', [
      'fetchCurrentProfile',
      'getCurrentProfileHash',
    ]),

    async startRegistration() {
      try {
        this.isLoading = true;
        await Promise.all([
          this.fetchRegistrationData(this.tournamentId),
          this.fetchUserProfile({
            hash: this.getCurrentProfileHash,
            complex: true,
          }),
          this.fetchCurrentProfile(),
        ]);
        this.$emit('startRegistration');
      } catch (err) {
        this.$emit('error');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
