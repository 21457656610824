<template>
  <div class="registration-without-team">
    <div class="registration-without-team__messages">
      <Notification
        :title="locale.notify"
        type="warning"
        show-icon
        horizontal
      />
      <Notification
        v-for="message in errorMessages"
        :key="message"
        :title="message"
        type="error"
        show-icon
        horizontal
      />
    </div>
    <div class="registration-without-team__name">
      <Input
        v-model="name"
        size="regular"
        :placeholder="locale.inputPlaceholder"
      />
    </div>
    <div class="registration-without-team__country">
      <Select
        v-model="country"
        :options="getCountries"
        :placeholder="locale.selectPlaceholder"
        size="regular"
        display-value="name"
        option-key="name"
      />
    </div>
    <div class="registration-without-team__logo">
      <TeamLogoCropper @input="handleCropperInput" />
    </div>
    <div class="registration-without-team__buttons">
      <Button
        :text="locale.createButton"
        size="regular"
        type="primary"
        :loading="isLoading"
        :disabled="isCreateButtonDisabled"
        @click="handleCreateButtonClick"
      />
    </div>
  </div>
</template>

<script>
import Notification from '@components/v2/ui/Notification.vue';
import Input from '@components/v2/ui/Input.vue';
import Select from '@components/v2/ui/Select.vue';
import TeamLogoCropper from './ui/TeamLogoCropper.vue';
import Button from '@components/v2/ui/Button.vue';

import api from '@utils/api';
import { i18n } from '@src/localization/config';

export default {
  name: 'RegistrationWithoutTeam',
  components: {
    Notification,
    Input,
    Select,
    TeamLogoCropper,
    Button,
  },
  props: {
    gameId: {
      type: Number,
      required: true,
    },
    teamSize: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      nameMinLength: 3,
      country: {},
      image: null,
      teamHash: '',
      errorMessages: [],
      isLoading: false,
      locale: {
        notify: i18n.t('registration.team'),
        inputPlaceholder: i18n.t('tournament.team_name'),
        selectPlaceholder: i18n.t('players.country_select'),
        createButton: i18n.t('teams.create_button'),
      },
    };
  },
  computed: {
    ...mapGetters('application', ['getCountries']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    isCreateButtonDisabled() {
      return this.name.length < this.nameMinLength;
    },
  },
  methods: {
    ...mapActions('users', ['fetchUserProfile']),

    async createTeam() {
      const response = await api.post('/team/create', {
        form: {
          name: this.name,
          numMembers: this.teamSize,
          idGame: this.gameId,
          idCountry: this.country.id,
        },
      });

      this.teamHash = response.team.hash;
      if (this.teamHash) {
        this.$emit('teamCreated', response.team);
      }
    },
    async createTeamLogo() {
      if (!this.image) return;
      const formData = new FormData();
      formData.append('type', 'team');
      formData.append('hash', this.teamHash);
      Object.entries(this.image).forEach(([key, value]) =>
        formData.append(key, value),
      );
      await api.post('/file/uploadandresize', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    async handleCreateButtonClick() {
      this.isLoading = true;

      try {
        await this.createTeam();
        await this.createTeamLogo();
        this.$emit('success');
        await this.fetchUserProfile({
          hash: this.getCurrentProfileHash,
          complex: true,
        });
      } catch (error) {
        const errors = JSON.parse(error.message);
        this.errorMessages = Object.values(errors);
        this.$emit('error');
      }

      this.isLoading = false;
    },
    async handleCropperInput({ data }) {
      this.image = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-without-team__messages {
  display: grid;
  gap: 8px;
}
.registration-without-team__messages,
.registration-without-team__country,
.registration-without-team__logo {
  margin-bottom: 24px;
}
.registration-without-team__name {
  margin-bottom: 12px;
}
.registration-without-team__buttons {
  display: flex;
  justify-content: center;
}
</style>
