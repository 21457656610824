<template>
  <div v-if="isVisibleComponent" class="pd-personal-team">
    <h3 class="pd-personal-team__title">{{ pdTeamMainTitle }}</h3>

    <Notification
      :title="pdTeamTitle"
      type="warning"
      show-icon
      horizontal
    />

    <p>{{ pdTeamLabel }}</p>

    <div v-if="invitationLink" class="pd-personal-team__clipboard">
      <ClipboardInput
        size="small"
        :value="invitationLink"
        copy-label="Скопировать"
      />
    </div>

    <InfoBox v-if="error" variant="error">
      {{ error }}
    </InfoBox>
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';
import ClipboardInput from '@components/v2/ui/ClipboardInput.vue';
import Notification from '@components/v2/ui/Notification.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'PersonalDataTeamRequired',
  components: {
    Notification,
    ClipboardInput,
    InfoBox,
  },

  props: {
    tournamentId: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      invitationLink: null,
    };
  },

  computed: {
    ...mapGetters('tournamentRegistration', ['isNeedFksPDFields']),
    ...mapGetters('tournaments', ['getTournament']),

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    numTeamMembers() {
      return this.tournament?.numTeamMembers;
    },

    isTeamTournament() {
      return this.numTeamMembers > 1;
    },

    isVisibleComponent() {
      return this.isNeedFksPDFields && this.isTeamTournament;
    },

    pdTeamMainTitle() {
      return i18n.t('registration.title');
    },
    pdTeamTitle() {
      return i18n.t('tournaments.pd-team-required-info');
    },
    pdTeamLabel() {
      return i18n.t('tournaments.pd-team-required-label');
    },
  },

  mounted() {
    const path = this.$router.resolve({
      name: 'profile-edit',
      hash: '#fcs',
    })?.href;
    this.invitationLink = `${window.location.origin}${path}`;
  },
};
</script>

<style lang="scss" scoped>
.pd-personal-team {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.pd-personal-team__title {
  font-size: 24px;
  text-align: center;
}
</style>
