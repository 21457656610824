<template>
  <div>
    <SelectTeam
      :tournament-id="tournamentId"
      @selectTeam="selectTeam"
      @goToNextStep="goToNextStep"
    />
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';
import SelectTeam from './ui/SelectTeam.vue';

export default {
  name: 'RegistrationWithManyTeams',
  components: { SelectTeam },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTeam: {},
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam']),

    buttonText() {
      return i18n.t('global.register');
    },
  },
  methods: {
    selectTeam(team) {
      this.selectedTeam = team;
    },
    goToNextStep() {
      this.$emit('goToNextStep', this.selectedTeam);
    },
  },
};
</script>

<style lang="scss" scoped></style>
