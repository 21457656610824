import api from '@utils/api.js';

export default async () => {
  const tournaments = await api.get('/profile/tournament/my');
  if (Array.isArray(tournaments)) {
    const teams = tournaments.reduce((teams, tnStatus) => {
      const participationInfo =
        tnStatus?.participationInfo?.participationInfo;

      if (participationInfo) {
        const { team, closestMatch } = participationInfo;

        if (team) {
          teams.push(team);
        }

        const enemyTeam = closestMatch?.team;

        if (enemyTeam) {
          teams.push(enemyTeam);
        }
      }

      return teams;
    }, []);

    return { tournaments, teams };
  };
}
