<template>
  <div>
    <CheckUserProfile
      v-if="userProfileFields.length > 0 && !showCheckContacts"
      :force-allow-edit="true"
      :fields="userProfileFields"
      @done="finishCheckUserFields"
    />
    <CheckUserContacts
      v-if="isVisibleCheckContacts"
      :force-allow-edit="true"
      :is-show-all-fields="false"
      :fields="userProfileContacts"
      @done="$emit('teamValidated:success', 'isTeamValidated')"
    />
  </div>
</template>

<script>
import CheckUserProfile from '@src/features/checkUserProfile';
import CheckUserContacts from '@src/features/checkUserContacts';

export default {
  name: 'TeamValidate',
  components: {
    CheckUserProfile,
    CheckUserContacts,
  },
  props: {
    userProfileFields: {
      type: Array,
      default: () => [],
    },
    userProfileContacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCheckContacts: false,
    };
  },
  computed: {
    isVisibleCheckContacts() {
      return (
        (this.userProfileContacts.length > 0 &&
          this.userProfileFields.length === 0) ||
        this.showCheckContacts
      );
    },
  },
  methods: {
    finishCheckUserFields() {
      if (this.userProfileContacts.length > 0) {
        this.showCheckContacts = true;
      } else {
        this.$emit('teamValidated:success', 'isTeamValidated');
      }
    },
  },
};
</script>
