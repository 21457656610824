<template>
  <div class="registration-finish">
    <h3 class="registration-finish__title">{{ titleText }}</h3>
    <loader v-if="isLoading" />

    <InfoBox v-else-if="error.code" variant="error">
      <p v-if="error.code === 'fks_account_locked'">
        <i18n path="fksapi.programm_error">
          <a
            slot="name"
            href="https://resf.ru/membership/about/"
            target="_blank"
          >
            {{ $t('fksapi.programm') }}
          </a>
        </i18n>
      </p>
      <p v-else-if="error.code === 'discord_is_required_for_captain'">
        <i18n
          path='["bl.tournament.registration"]["error.discord_is_required_for_captain"]'
        >
          <BaseLink
            slot="link"
            :to="{ path: '/profile/edit', hash: '#contacts' }"
          >
            {{
              $t(
                '["bl.tournament.registration"]["error.discord_is_required_for_captain_link"]',
              )
            }}
          </BaseLink>
        </i18n>
      </p>
      <p v-else-if="error.text">{{ error.text }}</p>

      <template v-if="error.details.errors.length">
        {{ $t('registration.errors_list') }}
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-for="(err, index) in error.details.errors"
          :key="index"
          v-html="err"
        ></div>
      </template>
      <!-- TODO легаси-код с обработкой ошибкой, поставить таск на рефакторинг -->
      <template v-if="error.details.players.length">
        <template v-if="error.code === 'team_members_intersected'">
          <i18n path="registration.errors_team">
            <b slot="name">
              <BaseTeamLink
                :hash="team.hash"
                :display-image="false"
              />
            </b>
          </i18n>
          {{ $t('registration.errors_otherTeam') }}:
        </template>
        <template v-else>
          {{ $t('registration.errors_players') }}
        </template>
        <ul>
          <li
            v-for="player in error.details.players"
            :key="player.hash"
          >
            {{ $t('players.single') }}
            <BaseLink
              :to="{ name: 'profile', params: { hash: player.hash } }"
            >
              {{ player.nick }}
            </BaseLink>
            <template v-if="error.code === 'players_banned'">
              {{ $t('registration.errors_participation') }}
              <template v-if="player.banReason">
                {{ $t('matches.complain_reason') }}: «{{
                  player.banReason
                }}».
              </template>
              <template v-if="player.banUntil">
                {{
                  $t('registration.errors_ban', {
                    date: $dt(player.banUntil, 'dd_MM_YYYY'),
                    time: $dt(player.banUntil, 'HH_mm'),
                  })
                }}
              </template>
            </template>
            <div
              v-for="(err, index) in player.errors"
              :key="index"
              v-html="err"
            ></div>
          </li>
        </ul>
      </template>

      <div v-if="error.code === 'fks_personal_data_required'">
        <Button
          tag="button"
          size="regular"
          type="primary"
          :text="buttonTextFill"
          @click="openPdPopup()"
        />
      </div>
    </InfoBox>

    <template v-else>
      <Icon
        class="registration-finish__icon"
        name="check-circle"
        :size="32"
      />
      <Button
        v-if="tournament"
        tag="router-link"
        size="regular"
        type="primary"
        :to="goToTournament"
        :text="tournament.name"
        class="registration-finish__link"
      />
      <BaseSocialLinks
        v-if="share"
        class="registration-finish__links"
        :share-link="share.link"
        :share-title="share.title"
      >
        <template slot="title">{{ shareTitle }}</template>
      </BaseSocialLinks>
      <Button
        size="regular"
        type="primary"
        :text="buttonText"
        @click="onDone"
      />
    </template>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Button from '@components/v2/ui/Button.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import { i18n } from '@src/localization/config';
import registerTournament from './model/registerTournament.js';
import fetchTournamentStatus from './model/fetchTournamentStatus.js';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'RegistrationFinish',
  components: {
    Icon,
    Button,
    BaseTeamLink,
    BaseSocialLinks,
    BaseLink,
    InfoBox,
  },
  props: {
    tournamentId: {
      type: [Number, String],
      required: true,
    },
    teamHash: {
      type: String,
      default: '',
    },
    heartstoneDecks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      isSuceessRegistered: false,
      error: {
        code: '',
        text: '',
        details: {},
      },
    };
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('tournamentRegistration', ['settings']),

    titleText() {
      return this.isSuceessRegistered
        ? i18n.t('registration.registered')
        : i18n.t('registration.registering');
    },

    buttonText() {
      return i18n.t('global.done');
    },

    buttonTextFill() {
      return i18n.t('global.fill');
    },

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    goToTournament() {
      return {
        name: 'tournament',
        params: { tournamentId: this.tournamentId },
      };
    },

    share() {
      return {
        link: `${window.location.origin}/tournament/${this.tournamentId}`,
        title: this.$t('registration.shareTitle', {
          name: this.tournament?.name,
        }),
      };
    },

    shareTitle() {
      return i18n.t('registration.share');
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await registerTournament({
        tournamentId: this.tournamentId,
        teamHash: this.teamHash,
      });
      this.successRegistered();
    } catch (err) {
      const { code, data, text } = err?.error;
      const players = Object.values(
        _.merge(
          {},
          _.keyBy(data?.players || [], 'hash'),
          _.keyBy(data?.playersErrors || [], 'hash'),
        ),
      );

      this.error = {
        code: code || err.error.errorCode,
        text:
          (code === 'team_members_intersected' ? '' : text) ||
          err.error.errorMessage,
        details: {
          players,
          errors: data?.errors || [],
        },
      };
      this.$emit('error', this.error);
    } finally {
      this.isLoading = false;
      const data = await fetchTournamentStatus();
      this.$store.dispatch(
        'tournaments/storeCurrentUserTournaments',
        data?.tournaments,
        { root: true },
      );
      this.$store.dispatch('teams/storeTeams', data?.teams, {
        root: true,
      });
      if (this.heartstoneDecks.length) {
        await this.fetchHSpickban();
      }
    }
  },
  methods: {
    successRegistered() {
      this.isSuceessRegistered = true;
    },
    onDone() {
      this.$emit('done');
    },
    fetchHSpickban() {
      const uploadPick = () =>
        api
          .post(`/tournament/${this.tournamentId}/deck`, {
            pick: this.heartstoneDecks,
          })
          .catch(() => false);

      const { pickBanSystem } = this.settings?.hearthstone || {};

      if (pickBanSystem) {
        uploadPick();
      }
    },
    async openPdPopup() {
      try {
        const newWindow = window.open();
        const { url } = await api.get('/profile/resf/pd-link');
        newWindow.location = url;
      } catch (err) {
        this.error = err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.registration-finish__icon {
  color: $dark-pastel-green;
}
.registration-finish__link {
  display: block;
  cursor: pointer;
}
::v-deep .social-links {
  justify-content: center;
}
</style>
