<template>
  <RegistrationWithPD :tournament-id="tournamentId" />
</template>

<script>
import RegistrationWithPD from '@src/features/registrationWithPD';

export default {
  name: 'FormPD',
  components: {
    RegistrationWithPD,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
};
</script>
