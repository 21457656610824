<template>
  <div class="accept-terms">
    <div class="accept-terms__accept-block">
      <Checkbox
        v-model="agreementApproved"
        name="agreementApprove"
        class="accept-terms__accept-checkbox"
        :disabled="agreementApproved"
        :text="acceptText"
        @change="onCheckboxClick"
      >
      </Checkbox>
      <span class="accept-terms__link-to-terms" @click="showTerms">
        {{ acceptTermsText }}
      </span>
    </div>
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';
import Checkbox from '@components/v2/ui/Checkbox.vue';

export default {
  name: 'AcceptTerms',
  components: {
    Checkbox,
  },
  data() {
    return {
      isVisbleTerms: false,
      agreementApproved: false,
    };
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfileDetails']),

    acceptText() {
      return i18n.t('organizations.request_accept');
    },
    acceptTermsText() {
      return i18n.t('terms.accept_fcs_agreement');
    },
    isTermsAccepted() {
      return this.getCurrentProfileDetails?.fcs?.isTermsAccepted;
    },
  },
  created() {
    this.agreementApproved = this.isTermsAccepted;
  },
  methods: {
    acceptTerms() {
      api
        .post('/profile/term/accept', { code: 'resf_agreement' })
        .then(({ success }) => {
          if (success) {
            this.agreementApproved = true;
            this.$emit('acceptTerms', this.agreementApproved);
            if (this.tournamentSettings?.needTermsOnly) {
              this.$emit('done');
            }
          }
        });
    },

    onCheckboxClick(isChecked) {
      if (isChecked) {
        this.acceptTerms();
      } else {
        this.agreementApproved = false;
      }
    },

    showTerms() {
      this.isVisbleTerms = true;
      this.$emit('showTerms', this.isVisbleTerms);
    },
  },
};
</script>

<style lang="scss" scoped>
.accept-terms__accept-block {
  display: flex;
  align-items: center;
  gap: 8px;
}
.accept-terms__link-to-terms {
  color: #16a7ff;
  cursor: pointer;
  text-decoration: underline;
}
</style>
