<template>
  <Button
    size="regular"
    type="primary"
    :text="buttonText"
    @click="startRegistration"
  />
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import { i18n } from '@src/localization/config';
import registrationAsUnauthorized from './model';

export default {
  name: 'RegistrationAsUnauthorized',
  components: { Button },
  computed: {
    buttonText() {
      return i18n.t('global.register');
    },
  },
  methods: {
    startRegistration() {
      return registrationAsUnauthorized();
    },
  },
};
</script>

<style lang="scss" scoped></style>
