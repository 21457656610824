<template>
  <RegistrationWithoutLinkedGame
    :game-id="gameId"
    @isGameLinked="$emit('isGameLinked')"
  />
</template>

<script>
import RegistrationWithoutLinkedGame from '@src/features/registrationWithoutLinkedGame';

export default {
  name: 'GameLink',
  components: {
    RegistrationWithoutLinkedGame,
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),

    tournamentId() {
      return this.$route.params.id;
    },
    tournament() {
      return this.getTournament(this.tournamentId);
    },
    gameId() {
      return this.tournament?.idGame;
    },
  },
};
</script>
