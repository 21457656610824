<template>
  <RegistrationFinish
    :tournament-id="tournamentId"
    :team-hash="teamHash"
    :heartstone-decks="heartstoneDecks"
    @done="$emit('closeModal')"
  />
</template>

<script>
import RegistrationFinish from '@src/features/registrationFinish';

export default {
  name: 'Result',
  components: {
    RegistrationFinish,
  },
  props: {
    teamHash: {
      type: String,
      default: '',
    },
    heartstoneDecks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
};
</script>
