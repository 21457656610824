<template>
  <div>
    <RegistrationWithoutTeam
      v-if="hasNoTeam"
      :game-id="gameId"
      :team-size="teamSize"
      @teamCreated="setCurrentTeam"
    />
    <RegistrationWithUnformedTeam
      v-else-if="isTeamNotFull"
      :team-hash="currentTeam.hash"
      :tournament-id="tournamentId"
      @finishRegistration="$emit('finishRegistration')"
    />
    <RegistrationWithManyTeams
      v-if="hasManyTeams && !currentTeam"
      :tournament-id="tournamentId"
      @goToNextStep="goToNextStep"
    />
  </div>
</template>

<script>
import RegistrationWithUnformedTeam from '@src/features/registrationWithUnformedTeam';
import RegistrationWithoutTeam from '@src/features/registrationWithoutTeam';
import RegistrationWithManyTeams from '@src/features/registrationWithManyTeams';

export default {
  name: 'TeamLink',
  components: {
    RegistrationWithoutTeam,
    RegistrationWithUnformedTeam,
    RegistrationWithManyTeams,
  },
  data() {
    return {
      currentTeam: null,
      isSelectedCurrentTeam: false,
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('profile', ['getCurrentProfile']),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('tournamentRegistration', ['settings']),

    tournamentId() {
      return this.$route.params.id;
    },
    tournament() {
      return this.getTournament(this.tournamentId);
    },
    gameId() {
      return this.tournament?.idGame;
    },
    teamSize() {
      return this.tournament?.numTeamMembers;
    },
    teamsList() {
      const profile = this.getCurrentProfile;
      const teams = this.getUserTeams(profile?.hash);
      const { gameId, teamSize } = this.settings || {};
      const spareLimit = this.tournament?.maxReservePlayersCount;
      const checkForSpareCondition = (count) =>
        spareLimit !== null ? count <= spareLimit : true;

      const res =
        teams?.filter(
          ({ idGame, numMembers, role, spareCount }) =>
            idGame === gameId &&
            numMembers === teamSize &&
            role === 'captain' &&
            checkForSpareCondition(spareCount),
        ) || [];
      return res;
    },
    teamStatus() {
      switch (this.teamsList?.length) {
        case 0:
          return 'noTeam';
        case 1:
          return 'hasOneTeam';
        default:
          return 'hasManyTeams';
      }
    },
    hasNoTeam() {
      return this.teamStatus === 'noTeam';
    },
    hasOneTeam() {
      return this.teamStatus === 'hasOneTeam';
    },
    hasManyTeams() {
      return this.teamStatus === 'hasManyTeams';
    },
    isTeamNotFull() {
      if (this.currentTeam) {
        return this.currentTeam?.status !== 'formed';
      } else {
        return false;
      }
    },
  },
  watch: {
    teamStatus: {
      handler(newStatus) {
        if (newStatus === 'hasOneTeam') {
          this.currentTeam = this.teamsList[0];
          this.finishRegistration(this.currentTeam);
        }
      },
      immediate: true,
    },
  },
  methods: {
    setCurrentTeam(team) {
      this.currentTeam = team;
      this.$emit('selectTeam', team);
    },
    finishRegistration(team) {
      const teamIsFull = team?.status === 'formed';
      if (teamIsFull) {
        this.$emit('finishRegistration');
      }
    },
    goToNextStep(team) {
      this.currentTeam = team;
      this.$emit('selectTeam', team);
      this.finishRegistration(team);
    },
  },
};
</script>
