import api from '@utils/api.js';

export default async ({ tournamentId, teamHash }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    return await api
      .post(`/tournament/${tournamentId}/registration`, {
        hash: teamHash,
      })
  } catch (err) {
    throw err;
  }
};
