<template>
  <div
    v-if="tournamentSettings.needFksAccount && !isFcsAccountLinked"
    class="check-fcs-account"
  >
    <div v-if="!isVisbleTerms" class="check-fcs-account__controls">
      <h3 class="check-fcs-account__title">{{ linkAccountTitle }}</h3>
      <div class="check-fcs-account__notification">
        <Notification type="info" :title="notifyTitle" />
      </div>
      <div class="check-fcs-account__accept-block">
        <div class="check-fcs-account__terms">
          <AcceptTerms
            @showTerms="showTerms"
            @acceptTerms="acceptTerms"
          />
        </div>
      </div>
      <div>
        <Button
          v-if="!fcsAccount.isAccountLinked"
          :loading="loadingState.link"
          :disabled="isDisabledButton"
          type="primary"
          size="regular"
          :text="bindAccountText"
          @click="link"
        />
        <Button
          v-else
          :loading="loadingState.link"
          type="secondary"
          size="regular"
          :text="unbindAccountText"
          @click="unlink"
        />
      </div>
    </div>
    <div v-if="isVisbleTerms" class="check-fcs-account__terms">
      <Terms @closeTerms="closeTerms" />
    </div>
    <InfoBox v-if="error" variant="error" class="error">
      {{ error }}
    </InfoBox>
  </div>
</template>

<script>
import api from '@utils/api';
import { i18n } from '@src/localization/config';
import Terms from './Terms.vue';
import AcceptTerms from './AcceptTerms.vue';
import Button from '@components/v2/ui/Button.vue';
import Notification from '@components/v2/ui/Notification.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'CheckFcsAccount',
  components: {
    Terms,
    Button,
    AcceptTerms,
    Notification,
    InfoBox,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      agreementApproved: false,
      isVisbleTerms: false,
      loadingState: {
        link: false,
        pd: false,
      },
      error: null,
    };
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('profile', ['getCurrentProfileDetails']),

    fcsAccount() {
      return this.getCurrentProfileDetails.fcs;
    },

    isFcsAccountLinked() {
      return this.fcsAccount?.isAccountLinked;
    },

    tournamentSettings() {
      const { needFksAccount, needFksTerms } =
        this.getTournament(this.tournamentId) || {};

      return {
        needFksAccount,
        needFksTerms,
        needTermsOnly: needFksTerms && !needFksAccount,
      };
    },
    linkButtonDisabled() {
      return !this.agreementApproved;
    },
    linkAccountTitle() {
      return i18n.t('registration.fcs_title');
    },
    notifyTitle() {
      return i18n.t('registration.errors_fcs');
    },
    acceptTermsText() {
      return i18n.t('terms.accept_fcs_agreement');
    },
    bindAccountText() {
      return i18n.t('account.bind');
    },
    unbindAccountText() {
      return i18n.t('account.unbind');
    },
    fcsPdText() {
      return i18n.t('profile.fcs_pd_title');
    },
    fillPdText() {
      return i18n.t('profile.fcs_fill_pd');
    },
    isDisabledButton() {
      return !this.agreementApproved;
    },
  },
  created() {
    this.agreementApproved =
      this.getCurrentProfileDetails?.fcs?.isTermsAccepted;
  },

  methods: {
    ...mapActions('profile', ['fetchCurrentProfile']),

    showTerms(isVisbleTerms) {
      this.isVisbleTerms = isVisbleTerms;
    },
    closeTerms() {
      this.isVisbleTerms = false;
    },
    acceptTerms(isAproved) {
      this.agreementApproved = isAproved;
    },
    async link() {
      this.loadingState.link = true;
      const { path } = this.$router.currentRoute;
      try {
        const { url } = await api.get('/profile/resf/link', {
          params: {
            extra: path,
          },
        });
        window.location = `${url}?section=fcs`;
      } catch (error) {
        this.setError(error);
      } finally {
        this.loadingState.link = false;
      }
    },
    unlink() {
      this.loadingState.link = true;

      api
        .post('/profile/resf/unlink')
        .catch(({ error }) => {
          this.setError(error);
        })
        .finally(() => {
          this.fetchCurrentProfile().finally(() => {
            this.loadingState.link = false;
            this.checkFckAgreement();
          });
        });
    },
    checkFckAgreement() {
      this.agreementApproved =
        this.fcsAccount?.isTermsAccepted || false;
    },
    setError(error = null) {
      this.error = error;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-fcs-account__controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.check-fcs-account__title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
}
.check-fcs-account__subtitle {
  font-size: 24px;
  margin-bottom: 24px;
}
.check-fcs-account__terms,
.check-fcs-account__notification {
  margin-bottom: 12px;
}
.error {
  margin-top: 12px;
}
</style>
