<template>
  <div class="pick-deck">
    <h3 class="pick-deck__title">{{ title }}</h3>
    <HearthstonePick @done="emitDecks" />
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';
import HearthstonePick from '@components/TournamentComponents/Registration/HearthstonePick.vue';

export default {
  name: 'RegistrationHS',
  components: {
    HearthstonePick,
  },
  computed: {
    title() {
      return i18n.t('registration.title');
    },
  },
  methods: {
    emitDecks(decks) {
      this.$emit('success', decks);
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-deck__title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}
</style>
