<template>
  <registrationHS @success="emitDecks" />
</template>

<script>
import registrationHS from '@src/features/registrationHS';

export default {
  name: 'HeartstoneDecks',
  components: {
    registrationHS,
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
  },
  methods: {
    emitDecks(decks) {
      this.$emit('heartstone:success', decks);
    },
  },
};
</script>
