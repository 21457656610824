<template>
  <div class="input">
    <label v-if="label" class="input__label">
      {{ label }}
    </label>
    <div class="input__field">
      <input
        ref="inputField"
        v-model="inputValue"
        :type="type"
        class="input__value"
        :disabled="true"
      />
      <div class="tooltip">
        <button class="input__copy" @click="copy">
          <span class="tooltip__text">{{ copyLabelText }}</span>
          <Icon name="clone-icon" :size="24" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import { copyToClipboard } from '@utils/copyToClipboard';

export default {
  name: 'ClipboardInput',
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    copyLabel: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      inputValue: this.value,
      copyLabelText: this.copyLabel,
    };
  },
  methods: {
    copy() {
      copyToClipboard(this.inputValue);
      this.copyLabelText = 'Скопировано';
      setTimeout(() => {
        this.copyLabelText = 'Скопировать';
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  color: #f5f5f7;
  isolation: isolate;
  font-size: 16px;
  line-height: 24;
}

.input__value {
  width: 100%;
  height: 44px;
  line-height: 1.2;
  display: flex;
  position: relative;
  background: #454549;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 48px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  background: #454549;

  &:disabled {
    color: #f5f5f7;
  }
}
.input__field {
  width: 100%;
  position: relative;
  background: inherit;
}

.input__label {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #f5f5f7;
  margin-bottom: 12px;
  background: inherit;
  z-index: 1;
}

.input__copy,
.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: inherit;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.input__icon,
.input__clear {
  .input--small & {
    font-size: 14px;
  }
  .input--regular & {
    font-size: 16px;
  }
  .input--big & {
    font-size: 24px;
  }
  .input--large & {
    font-size: 24px;
  }
}

.tooltip .tooltip__text {
  visibility: hidden;
  width: 140px;
  line-height: 1.4;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltip__text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
  opacity: 1;
}
</style>
