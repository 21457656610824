<template>
  <div
    v-click-outside="closeSelectList"
    class="select"
    :class="selectModifyClasses"
  >
    <button
      class="select__button"
      :disabled="disabled"
      @click="selectListToggler"
    >
      <span
        class="select__text"
        :class="{ 'select__text--placeholder': isOptionNotSelected }"
        >{{ selectCurrentValue }}</span
      >
      <div class="select__chevron">
        <icon name="chevron" inline />
      </div>
    </button>
    <ul v-if="isShowSelectList" class="select__list">
      <li
        v-for="(option, idx) in options"
        :key="option.id"
        class="select__list-item"
      >
        <button
          class="select__option"
          type="button"
          :class="{
            'select__option--checked': idx === currentOptionIndex,
          }"
          @click="optionChangeHandler(idx)"
        >
          <icon
            class="select__option-icon"
            name="check-mark"
            :inline="false"
          />
          <span class="select__text">
            {{ option[displayValue] }}
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Select',
  components: {
    Icon,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: 'regular',
    },
    options: {
      type: Array,
      required: true,
    },
    displayValue: {
      type: String,
      required: true,
    },
    optionKey: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      currentOptionIndex: this.options.findIndex(
        (obj) => obj[this.optionKey] === this.value[this.optionKey],
      ),
    };
  },
  computed: {
    selectModifyClasses() {
      return [
        `select--${this.size}`,
        this.isShowSelectList ? 'select--open' : '',
      ];
    },
    currentOption() {
      return this.options.find(
        (obj) => obj[this.optionKey] === this.value[this.optionKey],
      );
    },
    selectCurrentValue() {
      return this.isOptionNotSelected
        ? this.placeholder
        : this.currentOption[this.displayValue];
    },
    isShowSelectList() {
      return this.isOpen && !this.disabled;
    },
    isOptionNotSelected() {
      return !Object.keys(this.value).length;
    },
  },
  methods: {
    selectListToggler() {
      this.isOpen = !this.isOpen;
    },
    closeSelectList() {
      this.isOpen = false;
    },
    optionChangeHandler(newOptionIndex) {
      this.currentOptionIndex = newOptionIndex;
      this.isOpen = false;
      this.$emit('input', this.options[this.currentOptionIndex]);
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
  position: relative;
}

.select:focus-within {
  .select__button {
    box-shadow: 0px 0px 0px 2px #d8d8d8;
  }
}

.select--small {
  font-size: 12px;
  line-height: 14px;

  .select__button,
  .select__option {
    padding: 8px 10px;
    height: 30px;
  }
  .select__option-icon {
    width: 14px;
    height: 14px;
  }
}

.select--regular {
  font-size: 14px;
  line-height: 16px;

  .select__button,
  .select__option {
    padding: 10px 12px;
    height: 36px;
  }
  .select__option-icon {
    width: 16px;
    height: 16px;
  }
}

.select--big {
  font-size: 16px;
  line-height: 24px;

  .select__button,
  .select__option {
    padding: 10px 16px;
    height: 44px;
  }
  .select__option-icon {
    width: 24px;
    height: 24px;
  }
}

.select--large {
  font-size: 18px;
  line-height: 24px;

  .select__button,
  .select__option {
    padding: 16px 20px;
    height: 56px;
  }
  .select__option-icon {
    width: 24px;
    height: 24px;
  }
}

.select--open {
  .select__chevron {
    transform: rotate(180deg);
  }
}

.select__button {
  width: 100%;
  display: flex;
  column-gap: 18px;
  justify-content: space-between;
  align-items: center;
  color: #f5f5f7;
  letter-spacing: 0.3px;
  background-color: #454549;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 6px;
  transition: background var(--transition-short) linear;
  position: relative;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: #57575b;
  }
  &:disabled {
    background: #373739;
    color: #4f4f4f;
  }
}

.select__chevron {
  stroke: currentColor;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--transition-short) linear;
}

.select__list {
  max-height: 440px;
  overflow: hidden auto;
  border: 1px solid #5a5a5e;
  background: #454549;
  border-radius: 6px;
  list-style: none;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  z-index: 1;
}

.select__option {
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: 100%;
  letter-spacing: 0.3px;
  color: #f5f5f7;
  background: none;
  border: none;
  transition: background var(--transition-short) linear;
  outline: none;

  &:hover,
  &:focus {
    background: #4e4e52;
  }
}

.select__option--checked {
  background: #343439;
}

.select__option-icon {
  visibility: hidden;

  .select__option--checked & {
    visibility: visible;
  }
}

.select__text {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__text--placeholder {
  color: #979797;
}
</style>
