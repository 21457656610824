<template>
  <div class="registration-with-unformed-team">
    <div class="registration-with-unformed-team__notify">
      <Notification
        type="warning"
        show-icon
        horizontal
        :title="notificationMessage"
      />
    </div>
    <div class="registration-with-unformed-team__data">
      <h3 class="registration-with-unformed-team__title">
        {{ title }}
      </h3>
      <router-link
        class="registration-with-unformed-team__team"
        :to="`/team/${teamHash}`"
        target="_blank"
      >
        {{ teamName }}
      </router-link>
      <p class="registration-with-unformed-team__text">
        <span>{{ hintParagraph }} </span>
        <span>
          {{ timeoutParagraph }}
          <Timer
            v-if="inviteExpiredTime"
            :initial="inviteExpiredTime"
            format="hh:mm:ss"
            countdown
          />
        </span>
      </p>
    </div>
    <div class="registration-with-unformed-team__clipboard">
      <ClipboardInput
        v-if="inviteHash"
        size="small"
        :value="inviteHref"
        copy-label="Скопировать"
      />
    </div>
    <div class="registration-with-unformed-team__footer">
      <Button
        tag="button"
        size="regular"
        type="primary"
        :text="registerText"
        @click="$emit('finishRegistration')"
      />
    </div>
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';
import Timer from '@components/v2/Timer.vue';
import Notification from '@components/v2/ui/Notification.vue';
import ClipboardInput from '@components/v2/ui/ClipboardInput.vue';
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'RegistrationWithUnformedTeam',
  components: {
    Timer,
    Button,
    Notification,
    ClipboardInput,
  },
  props: {
    tournamentId: {
      type: [Number, String],
      required: true,
    },
    teamHash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      teamName: '',
      teamSize: 0,
      inviteHash: '',
      inviteExpiredTime: 0,
    };
  },
  computed: {
    registerText() {
      return i18n.t('global.register');
    },
    inviteHref() {
      return `https://pvp.vkplay.ru/team/${this.teamHash}/invite/${this.inviteHash}`;
    },
    title() {
      return i18n.t('teams.single');
    },
    hintParagraph() {
      return i18n.t('teams.invite_hint');
    },
    timeoutParagraph() {
      return i18n.t('teams.invite_timeout');
    },
    notificationMessage() {
      const message = i18n.t('registration.teamNum_text', {
        players: i18n.tc(
          'registration.teamNum_players',
          this.teamSize,
        ),
      });
      return message;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.fetchTeam(), this.fetchInviteLink()]);
    },

    async fetchTeam() {
      const { team } = await api.get(`team/${this.teamHash}`);
      this.teamName = team.name;
      this.teamSize = team.numMembers;
    },
    async fetchInviteLink() {
      let invite = await api.get(`team/${this.teamHash}/invitation`);
      if (!invite?.inviteHash) {
        invite = await api.get(
          `team/${this.teamHash}/invitation/renew`,
        );
      }
      this.inviteHash = invite.inviteHash;
      this.inviteExpiredTime = Number(invite.expiredTimeFull.ts) || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-with-unformed-team {
  width: 100%;
}
.registration-with-unformed-team__notify {
  margin-bottom: 24px;
}
.registration-with-unformed-team__data {
  text-align: center;
  margin-bottom: 12px;
}
.registration-with-unformed-team__title {
  font-size: 16px;
}
.registration-with-unformed-team__team {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
.registration-with-unformed-team__clipboard {
  margin-bottom: 32px;
}
.registration-with-unformed-team__footer {
  display: flex;
  justify-content: center;
  color: white;
}
</style>
