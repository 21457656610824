<template>
  <div class="select-team">
    <h3 class="select-team__title">{{ modalTitle }}</h3>
    <div class="select-team__teams">
      <TeamSelect :list="teamsList" @selectTeam="selectTeam" />
    </div>
    <div v-if="isSelectedTeam" class="select-team__button">
      <Button
        size="regular"
        type="primary"
        :text="buttonText"
        @click="goToNextStep"
      />
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import TeamSelect from './TeamSelect.vue';
import { i18n } from '@src/localization/config';

export default {
  name: 'SelectTeam',
  components: {
    Button,
    TeamSelect,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSelectedTeam: false,
    };
  },
  computed: {
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('profile', ['getCurrentProfile']),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('tournamentRegistration', ['settings']),

    modalTitle() {
      return i18n.t('teams.select');
    },

    buttonText() {
      return i18n.t('global.next');
    },

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    teamsList() {
      const profile = this.getCurrentProfile;
      const teams = this.getUserTeams(profile?.hash);
      const { gameId, teamSize } = this.settings || {};
      const spareLimit = this.tournament?.maxReservePlayersCount;
      const checkForSpareCondition = (count) =>
        spareLimit !== null ? count <= spareLimit : true;

      return (
        teams?.filter(
          ({ idGame, numMembers, role, spareCount }) =>
            idGame === gameId &&
            numMembers === teamSize &&
            role === 'captain' &&
            checkForSpareCondition(spareCount),
        ) || []
      );
    },
  },
  methods: {
    goToNextStep() {
      this.$emit('goToNextStep');
    },

    selectTeam(team) {
      this.isSelectedTeam = true;
      this.$emit('selectTeam', team);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-team__title {
  font-size: 24px;
  margin-bottom: 32px;
  text-align: center;
}

.select-team__teams {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.select-team__button {
  text-align: center;
}
</style>
