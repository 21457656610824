<template>
  <div>
    <CheckFcsAgreement :tournament-id="tournamentId" />
    <CheckFcsAccount :tournament-id="tournamentId" />
    <PersonalDataSoloRequired :tournament-id="tournamentId" />
    <PersonalDataTeamRequired :tournament-id="tournamentId" />
  </div>
</template>

<script>
import CheckFcsAgreement from './ui/CheckFcsAgreement.vue';
import CheckFcsAccount from './ui/CheckFcsAccount.vue';
import PersonalDataSoloRequired from './ui/PersonalDataSoloRequired.vue';
import PersonalDataTeamRequired from './ui/PersonalDataTeamRequired.vue';

export default {
  name: 'RegistrationWithPD',
  components: {
    CheckFcsAgreement,
    CheckFcsAccount,
    PersonalDataSoloRequired,
    PersonalDataTeamRequired,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
