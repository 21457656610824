<template>
  <div class="team-logo-cropper">
    <div
      v-if="images.length && !uploadError"
      class="team-logo-cropper__crop"
    >
      <ImageCropper
        :height="250"
        :width="250"
        :src="image.src"
        @result="handleCropperResult"
      />
      <button
        class="team-logo-cropper__clear"
        @click="handleImageClearButtonClick"
      >
        <Icon name="x" :size="24" />
      </button>
    </div>
    <FilesUploader
      v-else
      v-model="images"
      :allowed-formats="imageFormats"
      :max-file-size="imageFileMaxSizeMb"
    />
  </div>
</template>

<script>
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';
import ImageCropper from '@components/BaseComponents/ImageCropper.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TeamLogoCropper',
  components: {
    FilesUploader,
    ImageCropper,
    Icon,
  },
  data() {
    return {
      images: [],
      imageFormats: ['jpg', 'jpeg', 'png'],
      imageFileMaxSizeMb: 5,
    };
  },
  computed: {
    image() {
      const [firstImage] = this.images;
      return firstImage;
    },
    uploadError() {
      return Boolean(this.image?.error);
    },
  },
  methods: {
    handleCropperResult(data) {
      this.$emit('input', {
        data: { ...data, file: this.image.file },
      });
    },
    handleImageClearButtonClick() {
      this.images = [];
      this.$emit('input', { data: null });
    },
  },
};
</script>

<style lang="scss" scoped>
.team-logo-cropper__crop {
  position: relative;
}
.team-logo-cropper__clear {
  color: white;
  background: none;
  border: none;
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
