<template>
  <div v-if="isFcsTermsRequired" class="check-fcs-agreement">
    <div>
      <div v-if="!isVisbleTerms">
        <h3 class="check-fcs-agreement__title">{{ policyTitle }}</h3>
        <div class="check-fcs-agreement__notification">
          <Notification type="info" :title="notifyTitle" />
        </div>
        <AcceptTerms @showTerms="showTerms" />
      </div>
      <div v-if="isVisbleTerms" class="check-fcs-agreement__terms">
        <Terms @closeTerms="closeTerms" />
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';
import Terms from './Terms.vue';
import AcceptTerms from './AcceptTerms.vue';
import Notification from '@components/v2/ui/Notification.vue';

export default {
  name: 'CheckFcsAgreement',
  components: {
    Terms,
    AcceptTerms,
    Notification,
  },
  props: {
    tournamentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisbleTerms: false,
    };
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('profile', ['getCurrentProfileDetails']),
    ...mapState('tournamentRegistration', ['settings']),

    tournamentSettings() {
      const { needFksAccount = true, needFksTerms = true } =
        this.getTournament(this.tournamentId) || {};

      return {
        needFksAccount,
        needFksTerms,
        needTermsOnly: needFksTerms && !needFksAccount,
      };
    },
    tournament() {
      return this.getTournament(this.tournamentId);
    },
    policyTitle() {
      return i18n.t('policy.terms');
    },
    acceptText() {
      return i18n.t('organizations.request_accept');
    },
    notifyTitle() {
      return i18n.t('registration.errors_terms');
    },

    isFcsTermsRequired() {
      const { fcs } = this.getCurrentProfileDetails;
      return (
        this.tournament?.needFksTerms &&
        !this.tournament?.needFksAccount &&
        !fcs?.isAccountLinked &&
        !fcs.isTermsAccepted
      );
    },
  },
  methods: {
    showTerms(isVisbleTerms) {
      this.isVisbleTerms = isVisbleTerms;
    },
    closeTerms() {
      this.isVisbleTerms = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-fcs-agreement__title,
.check-fcs-agreement__notification {
  margin-bottom: 24px;
}
.check-fcs-agreement__title {
  font-size: 24px;
  text-align: center;
}
</style>
