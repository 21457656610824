<template>
  <label class="checkbox">
    <input
      v-model="isChecked"
      class="checkbox__input"
      type="checkbox"
      :name="name"
      :disabled="disabled"
      @change="onChangeHandler"
    />
    <span class="checkbox__appearance">
      <Icon name="check" />
    </span>
    <span v-if="isShowText" class="checkbox__text">
      {{ text }}
    </span>
  </label>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'Checkbox',
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.value,
    };
  },
  computed: {
    isShowText() {
      return this.text.length;
    },
  },
  methods: {
    onChangeHandler() {
      this.$emit('input', this.isChecked);
      this.$emit('change', this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox__appearance {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius--little);
  position: relative;
  color: transparent;
  border: 1px solid var(--main-color-o-gray);
}

.checkbox__text {
  color: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 8px;
}

// default
.checkbox__input:not(:checked) ~ .checkbox__appearance {
  background: var(--button-bg-tertiary);
}
.checkbox__input:not(:disabled) ~ .checkbox__text {
  color: var(--main-color-white);
}

// checked
.checkbox__input:checked ~ .checkbox__appearance {
  background: var(--main-color-white);
  color: var(--main-color-darkgray);
}

// hover
.checkbox:hover .checkbox__input:not(:checked):not(:disabled) {
  & ~ .checkbox__appearance {
    color: var(--main-color-o-gray);
  }
}

// focus
.checkbox__input:focus-visible {
  & ~ .checkbox__appearance:before {
    content: '';
    display: block;
    position: absolute;
    inset: -3px;
    background: transparent;
    z-index: -1;
    box-shadow: 0px 0px 0px 2px var(--button-color-light);
    border-radius: inherit;
  }
}

// disabled
.checkbox__input:disabled ~ .checkbox__appearance {
  background: var(--button-bg-disabled);
}
.checkbox__input:disabled ~ .checkbox__text {
  color: var(--additional-color-gray);
}
</style>
